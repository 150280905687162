import decode from "jwt-decode";
import axios from "axios";
import store from "../store";
import {getLocationCookie, setLocationCookie} from "./location";
import ModalContent from "./modals";
export const SESSION_COOKIE = "session";

export function getCookies() {
  let cookie = document.cookie.split(";");
  let cookies = {};

  cookie.forEach((key) => {
    let keyVal = key.trim().split("=");
    cookies[keyVal[0]] = keyVal[1];
  });

  return cookies;
}

export function getSessionCookie() {
  return getCookies()[SESSION_COOKIE];
}

export async function createSession(username, password, app, admin) {
  let data;
  if (admin) {
    await axios
      .post("/auth/authenticate", {
        name: username,
        admin: admin,
      })
      .then((resp) => {
        data = resp.data;
      });
  } else {
    await axios
      .post("/auth/authenticate", {
        name: username,
        password: password,
      })
      .then((resp) => {
        data = resp.data;
      });
  }
  if (data.error) throw data.error;
  else {
    // get session data and store it in app data
    let decoded = decode(data.token);
    app.user.session_id = decoded.session_id;
    app.user.first_name = data.user.first_name;
    app.$store.state.user.name =
      data.user.first_name + " " + data.user.last_name;
    app.user.email = data.user.email;
    app.user.coordinator = decoded.coordinator;
    app.user.isAuthenticated = true;
    app.session_id = decoded.session_id;
    app.$store.state.profileComplete = decoded.profileComplete;

    // create a cookie for session data
    document.cookie = `${SESSION_COOKIE}=${data.token}`;
  }
}

export function destroySession(app) {
  let cookies = getCookies();
  let data = decode(cookies[SESSION_COOKIE]);

  axios
    .post("/deauthenticate", {
      session: data.session_id,
    })
    .then(() => {
      app.user.isAuthenticated = false;
      app.user.name = null;
      app.user.email = null;
      app.session_id = null;

      document.cookie = `${SESSION_COOKIE}=${cookies[SESSION_COOKIE]}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
    });
}

export function updateSession(app, callback) {
  let cookies = getCookies();
  let data = decode(cookies[SESSION_COOKIE]);
  let dataInfo;
  let resptData;

  axios
    .post("/auth/update-session", {
      session: data.session_id,
      email: app.emailInput,
    })
    .then((resp) => {
      resptData = resp.data;
      // destroying cookie
      // document.cookie = `session=${cookies["session"]}; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/`
      // creating a new cookie with the session data
      document.cookie = `${SESSION_COOKIE}=${resptData.token}; path=/`;

      // get session data and store it in app data
      dataInfo = decode(resptData.token);

      app.$store.state.user.name =
        resptData.user.first_name + " " + resptData.user.last_name;
      app.$store.state.user.email = resptData.user.email;
      app.$store.state.user.phone = resptData.user.phone;
      app.$store.state.user.link = resptData.user.link;
      app.$store.state.user.isAuthenticated = true;
      app.$store.state.session_id = dataInfo.session_id;
      app.$store.state.user.activated = dataInfo.activated;
      app.$store.state.profileComplete = dataInfo.profileComplete;
      app.$store.state.user.coordinator = dataInfo.coordinator;

      if (callback) callback();
    });
}

export function setAuthToken() {}

export function getAuthToken() {
  let cookies = getCookies();
  let session = cookies[SESSION_COOKIE] ? cookies[SESSION_COOKIE] : false;

  return session;
}

export function clearAuthToken() {}

export function isAuthenticated() {
  let cookies = getCookies();

  return cookies[SESSION_COOKIE] && store.state.user.isAuthenticated;
}

export function isCoordinator() {
  let cookies = getCookies();
  let decoded = decode(cookies[SESSION_COOKIE]);
  let coordinator = decoded.coordinator;
  return coordinator;
}


export function storeSessionData(data, app) {
  // get session data and store it in app data
  let dataInfo = decode(data.token);
  console.log('dataInfo');
  console.log(dataInfo);
  if (dataInfo.activated) {
    // create a cookie for session data
    setSessionCookie(data.token);
    let location = data.user.location;
    if (location && !getLocationCookie()) {
      if (location.split("-").length === 3) {
        location = location.replace(/\-/g, "|");
      }
      const parts = location.split("|");
      if (parts.length === 3) {
        setLocationCookie(parts[0], parts[1], parts[2]);
      }
    }
    app.$store.commit("setUser", data.user);
    app.$store.commit("setData", dataInfo);
  } else {
    app.$bus.$emit("redirectVerification", data.user._id);
    // app.$bus.$emit("alert", ModalContent.accountNotActivated);
  }
}

export function setSessionCookie(session) {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + 7);
  switch (process.env.VUE_APP_ENVIRONMENT) {
    case "development":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=localhost;expires=${expirationDate.toUTCString()};`;
      break;
    case "staging":
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.staging.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
    default:
      document.cookie = `${SESSION_COOKIE}=${session};path=/;domain=.incredevent.com;expires=${expirationDate.toUTCString()};`;
      break;
  }
}