<template>
  <div class="security">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <Card>
          <h2>You're almost there!</h2>
          <p>
            Check your email for a verification code and put it in the box
            below.
          </p>
          <FormulateForm
            v-model="formValues"
            @submit="handleSubmit"
            #default="{ isLoading }"
            autocomplete="false"
          >
            <FormulateInput
              type="text"
              name="code"
              placeholder="XXXXXX"
              validation="min:6,length|max:8,length|required"
              autocomplete="false"
            />
            <FormulateInput
              type="submit"
              :name="isPlannerVerification ? 'Login': 'Activate'"
              :loading="isLoading"
            />
            <!-- <a @click="window.alert('hellooo')"></a> -->
            <p class="resend-text">
              <span class="link" :class="{ disabled: disableSendCode }" @click="resendCode">Resend Code?</span> Don't
              forget to check your
              spam filter.
            </p>
            <p class="resend-text" v-if="disableSendCode">You can resend code again after 30 seconds.</p>
          </FormulateForm>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import ModalContent from "../helpers/modals";
import {storeSessionData} from "../helpers/auth";

export default {
  name: "verify",
  head() {
    return {
      title: "Verify Email",
    };
  },
  data() {
    return {
      formValues: {
        code: "",
      },
      disableSendCode: false,
      guest: null,
      isPlannerVerification: false
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  methods: {
    resendCode() {
      if (this.disableSendCode) {
        return;
      }
      this.disableSendCode = true;
      const site = this.$store.state.site;

      this.$axios
        .post("/account/resend-code", {
          user: this.id,
          site: site,
          isPlannerVerification: this.isPlannerVerification
        })
        .then(({data}) => {
          if (data.error) {
            this.$toast(data.error.message, {type: "error"});
          } else {
            this.$toast("Code Sent! Check Your Email.", {type: "success"});
          }
        })
        .catch(({response}) => {
          let errorData = response.data;
          if (errorData) {
            this.$bus.$emit("alert", {
              title: errorData.error.type,
              msg: errorData.error.message,
            });
          } else {
            const content = ModalContent.accountActivationFailed;
            this.$bus.$emit("alert", {
              msg: content.msg,
            });
          }
        });

      setTimeout(() => {
        this.disableSendCode = false;
      }, 30000);
    },
    async handleSubmit() {
      try {
        await this.$axios
          .post("/account/activate", {
            user: this.id,
            key: this.formValues.code,
            isPlannerVerification: this.isPlannerVerification
          })
          .then((resp) => {
            let data = resp.data;
            if (resp.status == 200) {
              this.guestLogin(data.temp);
            }
          });
      } catch (error) {
        let errorData = error.response.data;
        if (errorData) {
          this.$bus.$emit("alert", {
            msg: errorData.error.message,
          });
        } else {
          if (this.isPlannerVerification) {
            return;
          }
          const content = ModalContent.accountActivationFailed;
          this.$bus.$emit("alert", {
            msg: content.msg,
          });
        }
      }
    },
    async guestLogin(temp) {
      if (!this.guest) {
        await this.$axios
          .post("/guest/login", {
            id: temp,
          })
          .then((response) => {
            let content = ModalContent.accountActivatedToProfile
            if (response.data.hasOwnProperty("token")) {
              this.guest = response.data;
              storeSessionData(this.guest, this.$root);
              if (this.isPlannerVerification) {
                this.$router.push("/brands");
                content = null;
              } else {
                this.$router.push("/profile");
              }
            } else {
              content = ModalContent.accountActivated;

              if (this.$store.state.site === "buyers") {
                this.$router.push("/login-register");
              } else {
                this.$router.push("/");
              }
            }
            if(content) {
              this.$bus.$emit("alert", {
                title: content.title,
                msg: content.msg,
                confirm: content.confirm,
              });
            }
          });
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Verify Email");
    this.isPlannerVerification = this.$route.query.planner ? true : false;
  },
};
</script>

<style lang="less" scoped>
.content-inner {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    display: flex;
    width: 450px;
    height: 350px;
    margin: 60px auto;
    padding: 20px;
    border: 1px solid var(--input-border);
    border-radius: 8px;
    background-image: var(--input-background);
    box-shadow: var(--dashboard-shadow);
    flex-direction: column;
    justify-content: center;

    h2,
    p {
      text-align: center;
    }

    h2 {
      margin-top: 0;
    }

    &::v-deep .formulate-input-element--submit button {
      width: 100%;
    }
  }

  p.resend-text {
    width: 100%;

    .link {
      color: var(--primary-blue);
      cursor: pointer;

      &.disabled {
        color: var(--gray-6);
      }
    }
  }
}
</style>
